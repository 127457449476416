import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const LogoHeroImage = ({ url, altText }) => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // Then we set the value in the --vh custom property to the root of the document
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    let vh = window && window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  if (url) {
    return (
      <div className="w-100 huis-logo-hero-image">
        <img
          src={url}
          alt={altText}
          className="huis-logo-hero-image__image"
        ></img>
      </div>
    )
  }
}

LogoHeroImage.defaultProps = {
  url: '',
  altText: '',
}

LogoHeroImage.propTypes = {
  url: PropTypes.string,
  altText: PropTypes.string,
}

export default LogoHeroImage
