import React from 'react'
import { Container } from 'react-bootstrap'
import { Header, Footer } from '../components'

const Layout = ({
  pageInfo,
  children,
  footerProps,
  homePageHasLogo = false,
}) => (
  <>
    <Container fluid className="px-0 main">
      <Header pageInfo={pageInfo} homePageHasLogo={homePageHasLogo} />
      <main>{children}</main>
    </Container>
    <Container>
      <Footer {...footerProps} />
    </Container>
  </>
)

export default Layout
