import React from 'react'
import PropTypes from 'prop-types'

const HeroImage = ({ url, altText, children }) => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // Then we set the value in the --vh custom property to the root of the document
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    let vh = window && window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  if (url) {
    return (
      <div
        role="img"
        className="img-fluid w-100 huis-hero-image"
        style={{
          backgroundImage: `url('${url}')`,
        }}
        aria-label={altText}
      >
        {children}
      </div>
    )
  }
}

HeroImage.defaultProps = {
  url: '',
  altText: '',
}

HeroImage.propTypes = {
  url: PropTypes.string,
  altText: PropTypes.string,
}

export default HeroImage
