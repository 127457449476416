import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, keywords, title }) => {
  const { prismicSeo } = useStaticQuery(
    graphql`
      query {
        prismicSeo {
          data {
            keywords {
              keyword
            }
            site_image {
              url
              alt
              dimensions {
                height
                width
              }
            }
            title {
              text
            }
            description {
              text
            }
          }
        }
      }
    `
  )
  const seo = prismicSeo.data

  title = title || seo.title.text
  const metaKeywords = (keywords.length && keywords) || seo.keywords
  const metaDescription = description || seo.description.text
  const metaImageSrc = seo.site_image.url || ''
  const metaImageAlt = seo.site_image.alt || ''
  const {
    height: metaImageHeight,
    width: metaImageWidth,
  } = seo.site_image.dimensions

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: metaImageSrc,
        },
        {
          property: 'og:image:width',
          content: metaImageWidth,
        },
        {
          property: 'og:image:height',
          content: metaImageHeight,
        },
        {
          name: `twitter:card`,
          content: `summary`, // MUST be set to a value of "summary"
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          //Images MUST BE 1:1 AR. min: 144x144px & max: 4096x4096px
          name: `twitter:image`,
          content: metaImageSrc,
        },
        {
          name: `twitter:image:alt`,
          content: metaImageAlt,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: keywords.map((obj) => obj.keyword).join(','),
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
