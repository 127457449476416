import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Input,
  FormControl,
} from 'react-bootstrap'

const MailingList = ({
  imgSrc,
  altText,
  title,
  text,
  postUrl,
  userId,
  audienceId,
  emailInputName,
}) => {
  const block = imgSrc ? 'huis-mailing-list-with-image' : 'huis-mailing-list'
  if (postUrl) {
    return (
      <div
        role={imgSrc ? 'img' : null}
        className={
          imgSrc ? `img-fluid w-100 ${block} mb-4` : `w-100 ${block} mb-4`
        }
        style={
          imgSrc
            ? {
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url('${imgSrc}')`,
              }
            : {}
        }
        aria-label={altText}
      >
        <Container className="text-center">
          <Row>
            <Col
              sm={{ span: 10, offset: 1 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <h2 className={`${block}__title`}>{title}</h2>
            </Col>
          </Row>
          <Row>
            <Col
              sm={{ span: 10, offset: 1 }}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
            >
              <h3 className={`${block}__text`}>{text}</h3>
            </Col>
            <Col
              xs={{ span: 10, offset: 1 }}
              sm={{ span: 8, offset: 2 }}
              md={{ span: 6, offset: 3 }}
              lg={{ span: 4, offset: 4 }}
            >
              {/* Todo: https://mailchimp.com/help/host-your-own-signup-forms/ */}
              <Form action={postUrl} method="POST" target="_blank">
                <Form.Row className="align-items-center">
                  <Col>
                    <Form.Control type="hidden" name="u" value={userId} />
                    <Form.Control type="hidden" name="id" value={audienceId} />
                    <Form.Label htmlFor="subscribe-email-input" srOnly>
                      Email
                    </Form.Label>
                    <InputGroup>
                      <FormControl
                        id="subscribe-email-input"
                        name={emailInputName}
                        placeholder="you@email.com"
                        type="email"
                      />
                      <InputGroup.Append>
                        <Button type="submit" variant="primary">
                          Subscribe
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Form.Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  return null
}

MailingList.defaultProps = {
  imgSrc: '',
  altText: '',
  title: '',
  text: '',
  postUrl: '',
  userId: '',
  audienceId: '',
  emailInputName: '',
}

MailingList.propTypes = {
  imgSrc: PropTypes.string,
  altText: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  postUrl: PropTypes.string,
  userId: PropTypes.string,
  audienceId: PropTypes.string,
  emailInputName: PropTypes.string,
}

export default MailingList
