import React from 'react'
import { Link } from 'gatsby'
import { Navbar, Nav } from 'react-bootstrap'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { useStaticQuery, graphql } from 'gatsby'

const Header = ({ pageInfo, homePageHasLogo = false }) => {
  const { prismicHeader } = useStaticQuery(
    graphql`
      query {
        prismicHeader {
          data {
            googleMaps: google_maps_link {
              url
            }
            logo {
              url
              alt
            }
          }
        }
      }
    `
  )
  const { googleMaps, logo } = prismicHeader.data
  const googleMapsUrl = googleMaps.url
  const block = 'huis-header'
  return (
    <>
      <Navbar bg="light" expand="md" sticky="top" className="huis-header">
        <Link
          to="/"
          className="link-no-style"
          aria-label="Homepage for Huis Shave Ice"
        >
          {logo.url && (
            <Navbar.Brand
              as="div"
              className={`${block}__logo-container ${
                typeof pageInfo === 'undefined' && homePageHasLogo
                  ? `${block}__logo-container--display-none`
                  : ''
              }`}
            >
              <img
                src={logo.url}
                className="huis-header__logo"
                alt="Huis Logo"
              ></img>
            </Navbar.Brand>
          )}
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/menu" className="link-no-style">
              <Nav.Link as="span" eventKey="menu">
                Menu
              </Nav.Link>
            </Link>
            <Link to="/our-story" className="link-no-style">
              <Nav.Link as="span" eventKey="our-story">
                Our Story
              </Nav.Link>
            </Link>
            <Link to="/gallery" className="link-no-style">
              <Nav.Link as="span" eventKey="gallery">
                Gallery
              </Nav.Link>
            </Link>
            <Link to="/contact-us" className="link-no-style">
              <Nav.Link as="span" eventKey="contact-us">
                Contact Us
              </Nav.Link>
            </Link>
          </Nav>
          <Nav className="ml-auto">
            <a
              href={googleMapsUrl}
              className="link-no-style"
              target="_blank"
              rel="noopener"
              onClick={() => {
                typeof window !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'outbound',
                    event_label: googleMapsUrl,
                    value: 'directions',
                  })
              }}
            >
              <Nav.Link as="span" eventKey="find-us">
                <FaMapMarkerAlt
                  className={`${block}__social-icon icon-google-maps`}
                />
                Find Us
              </Nav.Link>
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Header
