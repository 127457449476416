import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { FaMapMarkerAlt, FaFacebook, FaInstagram } from 'react-icons/fa'
import { useStaticQuery, graphql } from 'gatsby'

const Footer = ({ hasBorderTop }) => {
  const { prismicFooter } = useStaticQuery(
    graphql`
      query {
        prismicFooter {
          data {
            facebook: facebook_link {
              url
            }
            instagram: instagram_link {
              url
            }
            googleMaps: google_maps_link {
              url
            }
          }
        }
      }
    `
  )

  const { data } = prismicFooter
  const facebookUrl = data.facebook.url
  const instagramUrl = data.instagram.url
  const googleMapsUrl = data.googleMaps.url

  return (
    <>
      <Row noGutters>
        <Col>
          <footer
            className={`huis-footer ' ${
              hasBorderTop ? 'huis-footer--border-top' : ''
            }`}
          >
            <div>
              <ul className="huis-footer__social-icon-list">
                <li>
                  <a
                    href={facebookUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      typeof window !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'outbound',
                          event_label: facebookUrl,
                          value: 'footer facebook page',
                        })
                    }}
                  >
                    <FaFacebook
                      className="huis-footer__social-icon icon-facebook"
                      aria-label="Huis Shave Ice Facebook Page"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={instagramUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      typeof window !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'outbound',
                          event_label: instagramUrl,
                          value: 'footer instagram page',
                        })
                    }}
                  >
                    <FaInstagram
                      className="huis-footer__social-icon icon-instagram"
                      aria-label="Huis Shave Ice Instagram Page"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={googleMapsUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      typeof window !== 'undefined' &&
                        window.gtag('event', 'click', {
                          event_category: 'outbound',
                          event_label: googleMapsUrl,
                          value: 'footer directions icon',
                        })
                    }}
                  >
                    <FaMapMarkerAlt
                      className="huis-footer__social-icon icon-google-maps"
                      aria-label="Google Maps URL for Direction to Huis Shave Ice"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="huis-footer__copyright-container">
              <p>© {new Date().getFullYear()} Huis Shave Ice </p>
            </div>
          </footer>
        </Col>
      </Row>
    </>
  )
}

Footer.propTypes = {
  hasBorderTop: PropTypes.bool,
}

Footer.defaultProps = {
  hasBorderTop: true,
}

export default Footer
